@font-face {
  font-family: 'DMSans-Regular';
  src: url('fonts/DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: url('fonts/DMSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GTWalsheim-Md';
  src: url('fonts/GT-Walsheim-Medium.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'DMSans-Regular', 'DMSans-Bold', 'GTWalsheim-Md', Verdana,
    Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fef9ee;
}
